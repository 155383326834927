import React from 'react'
import './portfolio.css'
import NODEJS from '../../assets/node.png'
import CALSOFT from '../../assets/calsoft.png'
import AISUMMERIZER from '../../assets/aisummerizer.png'

function Portfolio() {
  const portfolio = [{
    img: CALSOFT,
    title: 'Calsoft - Wordpress',
    giturl: '',
    url: 'https://calsoftinc.com'
  },
  {
    img: AISUMMERIZER,
    title: 'AI Summerizer - React.js',
    giturl: 'https://github.com/sanadqazi/ai-article-summerizer-gpt-3',
    url: 'https://chic-treacle-285416.netlify.app/'
  },
  {
    img: NODEJS,
    title: 'Contact API - Node.js ',
    giturl: 'https://github.com/sanadqazi/node-express-mongo-contacts-api',
    url: ''
  }
]
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
        <h2>Portfolio</h2>
      <div className="container portfolio__container">

      {
        portfolio.map(({img,title,url,giturl},index) => {
          return (
          <article key={index} className="portfolio__item">
          <div className="portfolio_item-image">
            <img src={img} alt={title} />
          </div>
          <h3>{title}</h3>
          <div className="portfolio__item-cta">
            {giturl !== '' &&  <a href={giturl} className='btn' target='_blank'>Github</a>}
            {url !== '' &&  <a href={url} className='btn' rel='nofollow' target='_blank'>Live Demo</a>}
          </div>
        </article>
          )
        })
      }
      </div>
    </section>
  )
}

export default Portfolio