import React from 'react'
import './footer.css'
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {BiLogoLinkedin} from 'react-icons/bi'

function Footer() {
  return (
    <footer>
      <div className="footer__logo__container">
      <p className='footer__logo'>Sanad</p>
      <small>Full Stack Developer</small>
      </div>
    
      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://linkedin.com/in/qazisanad" target='_blank'><BiLogoLinkedin /></a>
        <a href="https://facebook.com/sanad.hussainqazi" target='_blank'><FaFacebookF /></a>
        <a href="https://instagram.com/qazisanad" target='_blank'><FiInstagram /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; 2023 Sanad Qazi. All Rights Reserved.</small>
      </div>
    </footer>
  )
}

export default Footer