import React, { useState } from 'react'
import './contact.css';
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import { useRef } from 'react';
import emailjs from 'emailjs-com';

function Contact() {
  const form = useRef();
  const [isSent, setIsSent] = useState('');
  const [submitText, setSubmitText] = useState('Submit');

  const sendEmail = (e) => {
    e.preventDefault();
    setSubmitText('Sending...');

    emailjs.sendForm('service_ehoz22k', 'template_mz1lkci', form.current, 'AdB4chKPmSYh6iFjM')
      .then((result) => {
        setIsSent(true);
        setSubmitText('Submit');
        e.target.reset();
      }, (error) => {
        setIsSent(false);
      });
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
          <MdOutlineEmail className='contact__option-icon' />
          <h4>Email</h4>
          <h5>sanadqazi@gmail.com</h5>
          <a href="mailto:sanadqazi@gmail.com" target='_blank'>Send a message</a>
          </article>

          <article className="contact__option">
          <RiMessengerLine className='contact__option-icon' />
          <h4>Messenger</h4>
          <h5>sanad.hussainqazi</h5>
          <a href="https://m.me/sanad.hussainqazi" target='_blank'>Send a message</a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS  */}

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Enter your name' required/>
          <input type="email" name='email' placeholder='Enter your email' required/>
          <input type="text" name='phone' placeholder='Enter your mobile number' required/>
          <textarea name="message" placeholder='Enter your message'></textarea>
          <button type='submit' className='btn btn-primary'>{submitText}</button>
          { isSent && <div className="email__sent-message">Thanks! I've received your message.</div> }
        </form>
        
        
      </div>
    </section>
  )
}

export default Contact