import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

function Services() {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <artile className="service">
          <div className="service__head">
            <h3>Frontend Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon' />
              <p>Responsive Web Design</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>HTML5 and CSS3 Mastery</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Cross-Browser Compatibility</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>User-Centric UI/UX Design</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Version Control and Collaboration</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Performance Optimization</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Modern Frameworks</p>
            </li>
          </ul>
        </artile>
        {/* END OF UI/UX  */}

        <artile className="service">
          <div className="service__head">
            <h3>Backend Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon' />
              <p>RESTful API Development</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Real-Time Functionality</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Scalable Architecture</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Custom Theme Development</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Plugin Customization</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Security Implementation</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>API Integration</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Performance Optimization</p>
            </li>
          </ul>
        </artile>
        {/* END OF WEB DEVELOPMENT  */}

        <artile className="service">
          <div className="service__head">
            <h3>Optimization</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon' />
              <p>Page Speed Optimization</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Image Compression and Optimization</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Minification of Code</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Browser Compatibility</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Caching Implementation</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Content Delivery Network (CDN) Integration</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Responsive Design Optimization</p>
            </li>
          </ul>
        </artile>
        {/* END OF CONTENT WRITING  */}
      </div>
    </section>
  )
}

export default Services