import React from 'react'
import './testimonials.css'
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR4 from '../../assets/avatar4.jpg'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

function Testimonials() {

  const reviews = [
    {
      name: "Emily Williams",
      avatar: AVTR1,
      review: "I am extremely satisfied with the full stack development services provided by Sanad. He created a seamless and efficient web application that perfectly meets our business needs.",
    },
    {
      name: "Jane Smith",
      avatar: AVTR2,
      review: "Working with Sanad was a fantastic experience. Their attention to detail and problem-solving skills are truly commendable. Our project was completed on time and within budget.",
    },
    {
      name: "Inaya",
      avatar: AVTR4,
      review: "I'm impressed by the expertise of Sanad as a full stack developer. He successfully transformed our complex ideas into a user-friendly application. The collaboration was smooth, and I highly recommend their services.",
    }
  ];
  
  return (
    <section id='testimonials'>
      <h5>Review from Clients</h5>
      <h2>Testimoials</h2>

      <Swiper className="container testimonials__container"
      modules={[Pagination]}
      spaceBetween={50}
      slidesPerView={1}
      pagination={{ clickable: true }}
      >
        {
        reviews.map(({avatar,name,review},index) => {
          return (
            <SwiperSlide key={index} className="testimonial">
            <div className="client__avatar">
                <img src={avatar} alt="Avatar One" />
              </div>
                <h5 className="client__name">{name}</h5>
                <small className="client__review">
                {review}
                </small>
            </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials