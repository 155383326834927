import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/me.png';
import HeaderSocials from './HeaderSocials';

function Header() {
  return (
   <header>
    <div className="container header__container">
      <h5>I'm</h5>
      <h1>Sanad Qazi</h1>
      <h5 className="text-light">Full Stack Developer</h5>
      <CTA />
      <HeaderSocials />
      <img className='me' src={ME} alt='me' />
      <a href="#about" className="scroll__down">Scroll Down</a>
    </div>
   </header>
  )
}

export default Header