import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FaInstagram} from 'react-icons/fa'


function HeaderSocials() {
  return (
    <div className='header__socials'>
        <a href='https://linkedin.com/in/qazisanad' target='_blank'><BsLinkedin/></a>
        <a href='https://github.com/sanadqazi' target='_blank'><FaGithub/></a>
        <a href='https://instagram.com/qazisanad' target='_blank'><FaInstagram/></a>
    </div>
  )
}

export default HeaderSocials